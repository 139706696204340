import React,{useState} from "react";
import {faFolderOpen} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import API from "../../utils/API";
import Loader from "../../components/Loader";
import Datetime from "./Datetime";
import PostingStatus from "../status/PostingStatus";

const OrderPostingLog = ({id,isPing}) => {
    const [modalshow,setModalshow] = useState(false)
    const [loading,setLoading] = useState(true)
    const [log,setLog] = useState(false)

    const showLog = () => {
        setModalshow(true)
        setLoading(true)
        API.get('/admin/lead/'+(isPing?'pinglog':'postinglog')+'/item/'+id).then((response) => {
            setLog(response.data.data)
            setLoading(false)
        });
    }

    return (
        <>
            <span onClick={()=>showLog()}>
                <FontAwesomeIcon
                    className="align-middle mr-2 cursor-pointer"
                    icon={faFolderOpen}
                    fixedWidth
                />
            </span>
            <React.Fragment>
                <Modal
                    isOpen={modalshow}
                    toggle={() => setModalshow(!modalshow)}
                    size="lg"
                >
                    <ModalHeader toggle={() => setModalshow(!modalshow)}>
                        Posting log
                    </ModalHeader>
                    <ModalBody>
                        {loading &&
                            <Loader/>
                        }
                        {(log && log.length > 0) &&
                            <>
                            {log.map((item, index) =>
                                <div key={index} className='mb-3' style={{borderBlock: "solid 1px #ccc"}}>
                                    <ul className="list-unstyled">
                                        <li className="mt-1"><PostingStatus status={item.status}/></li>
                                        <li className="mt-1"><Datetime dateTime={item.date}/></li>
                                        {item.sendtime &&
                                            <>
                                                <li className="mt-1"><strong>{item.sendtime}</strong> seconds</li>
                                                <li className="mt-1">URL: <strong>{item.url}</strong></li>
                                            </>
                                        }
                                        {(!item.sendtime && item.status === 'matched' && item.ping && !item.lead) &&
                                            <li className="mt-1"><strong>Auto match ping to direct post order</strong>
                                            </li>
                                        }
                                        {(!item.sendtime && item.status === 'matched' && item.lead) &&
                                            <li className="mt-1"><strong>Auto match pre-match to direct post
                                                order</strong></li>
                                        }
                                    </ul>
                                    {item.sendtime &&
                                        <>
                                            <div className="mt-3">
                                                <strong>Request</strong>
                                                <textarea className="form-control"
                                                          readOnly={true}>{item.requestdata}</textarea>
                                            </div>

                                            <div className="mt-3">
                                                <strong>Response</strong>
                                                <textarea className="form-control"
                                                          readOnly={true}>{item.responsedata}</textarea>
                                            </div>
                                        </>
                                    }
                                </div>
                            )}
                            </>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={() => setModalshow(!modalshow)}>
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        </>
    )
};

export default OrderPostingLog;
