import React, {useState} from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner} from "reactstrap";
import API from "../../../utils/API";

const ReverseReturn = ({returnItem, refreshList}) => {
    const [modalshow,setModalshow] = useState(false);
    const [note,setNote] = useState(null);
    const [saving,setSaving] = useState(false);

    const submit = () => {
        setSaving(true);
        API.post('/admin/return/request/reversedecline',{
            id:returnItem.id,
            note:note
        }).then((response)=>{
            setSaving(false);
            setModalshow(false);
            refreshList();
        }).catch((error)=>{})
    }

    return <>
        {returnItem.status === 'denied' &&
            <a className='text-primary' onClick={()=>setModalshow(true)}>reverse</a>
        }
        <React.Fragment>
            <Modal
                isOpen={modalshow}
                toggle={() => setModalshow(!modalshow)}
                size="lg"
                style={{maxWidth: '600px', width: '600px'}}
            >
                <ModalHeader toggle={() => setModalshow(!modalshow)}>
                    Reverse denied return
                </ModalHeader>
                <ModalBody style={{backgroundColor:"#F7F9FC"}}>
                    <h3>Are you sure you want to reverse?</h3>
                    <label>Memo (not required)</label>
                    <textarea rows={5} className="form-control" name="note" onChange={(e)=>setNote(e.target.value)} value={note}/>
                </ModalBody>
                <ModalFooter className="justify-content-center">
                    {saving ?
                        <Spinner color="primary" />
                        :
                        <Button color="secondary" onClick={() => submit()}>
                            Save
                        </Button>
                    }
                </ModalFooter>
            </Modal>
        </React.Fragment>
    </>
}

export default ReverseReturn;
